<template>
  <main class="main">
    <div class="main__inner">
      <section class="section">
        <h2 class="title">Новости</h2>
        <div class="filter__form-change-wrap">
          <form
            @submit.prevent="load(1)"
            ref="filterForm"
            class="filter__form filter__form-change"
            autocomplete="off"
          >
            <div class="search">
              <div class="search__input">
                <label for="news_search" class="search__label"> Поиск </label>
                <div class="search__input__wrap">
                  <input v-model="form.title.value" id="news_search" type="text" maxlength="255" />
                  <button v-if="form.title.value" type="button" @click="resetSearchInput">
                    <CloseIcon />
                  </button>
                </div>
              </div>
              <div class="search__btn">
                <button class="btn-blue btn--small" type="submit" :disabled="loading">
                  <LoadingIndicator title="Загрузка" v-if="loading" />
                  <template v-else>Найти</template>
                </button>
              </div>
            </div>
            <div class="filter">
              <div class="filter__item">
                <label>Сфера деятельности</label>
                <Multiselect
                  track-by="id"
                  label="title"
                  v-model="form.category_id.value"
                  :options="categories"
                  placeholder="Все сферы"
                  selectLabel="Выбрать ↵"
                  deselectLabel="Удалить ↵"
                  selectedLabel="Выбрано"
                  :searchable="true"
                  :allow-empty="true"
                >
                  <div slot="option" slot-scope="item">
                  <span :class="{ option__dedicated: !item.option.parent_id }" class="option">
                    {{ item.option.title }}
                  </span>
                  </div>
                  <span slot="noOptions">Список пуст</span>
                  <span slot="noResult">Ничего не найдено.</span>
                </Multiselect>
              </div>
              <div class="filter__item">
                <label>Диапазон дат</label>
                <div class="filter__date__inputs">
                  <div class="filter__date__input">
                    <flat-pickr v-model="form.date_start.value" :config="flatPickrConfig" placeholder="дд.мм.гггг">
                    </flat-pickr>
                    <DatePickIcon />
                  </div>
                  <div class="filter__date__input">
                    <flat-pickr v-model="form.date_end.value" :config="flatPickrConfig" placeholder="дд.мм.гггг">
                    </flat-pickr>
                    <DatePickIcon />
                  </div>
                </div>
              </div>
              <div class="filter__actions">
                <a @click.prevent="resetFilterForm" href="#" class="filter__reset"> Сбросить фильтр </a>
              </div>
            </div>
          </form>

          <a
            @click.prevent="changeFilterForm"
            class="filter__change-btn"
            :class="{ hide__btn: isFilterVisible }"
            href="#"
          >
            <span v-if="isFilterVisible">Скрыть фильтр</span>
            <span v-else>Поиск и фильтр по новостям</span>
            <ArrowDownIcon />
          </a>
        </div>

        <LoadingIndicator v-if="loading" title="Загрузка" />
        <div class="page__items" v-else>
          <p v-if="isFilter" class="result__text">
            По вашему запросу найдено совпадений: {{ this.news.paginatorInfo ? this.news.paginatorInfo.total : 0 }}
          </p>
          <template v-if="firstNews && firstNews.length">
            <div class="_items_list no-mobile">
              <router-link
                :to="{ name: 'news_item', params: { id: item.id, url: item.link } }"
                class="_item" v-for="(item, index) in firstNews" :key="index"
              >
                <div class="_item__img">
                  <div class="img__container">
                    <img :src="item.headImg | image($store.state.api)" :alt="item.headImg | image_alt" />
                  </div>
                </div>
                <div class="news__mayor__header _item__header">
                  <div v-if="item.category && item.category.title" class="_item__category">
                    {{ item.category.title }}
                  </div>
                  <div class="_item__time" v-if="item.date_publication">
                    <time :time="item.date_publication | robotDate">
                      {{ item.date_publication | humanDateRu }}
                    </time>
                  </div>
                </div>
                <div v-if="item.title" class="_item__title">
                  {{ item.title }}
                </div>
              </router-link>
            </div>
            <div class="slider__mobile">
              <SliderComponent :news="firstNews" />
            </div>

            <div v-if="newsList && newsList.length" class="news__list">
              <router-link
                v-for="(item, index) in newsList"
                :key="index"
                :to="{ name: 'news_item', params: { id: item.id, url: item.link } }"
                class="_item"
              >
                <div class="_item__header">
                  <div v-if="item.category && item.category.title" class="_item__category">
                    {{ item.category.title }}
                  </div>
                  <div class="_item__time" v-if="item.date_publication">
                    <time :time="item.date_publication | robotDate">
                      {{ item.date_publication | humanDateRu }}
                    </time>
                  </div>
                </div>
                <div v-if="item.title" class="_item__title">
                  {{ item.title }}
                </div>
              </router-link>
            </div>

            <PaginationComponent
              v-if="
                page !== null &&
                news.paginatorInfo.total !== null &&
                news.paginatorInfo.total > first
              "
              :total="Math.ceil(news.paginatorInfo.total / first)"
              @change="paginate"
              :page="page"
            />
          </template>
          <p v-else-if="!isFilter" class="_item__title">Новости отсутствуют</p>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import FlatPickr from 'vue-flatpickr-component'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import Multiselect from 'vue-multiselect'
import DatePickIcon from '@/components/svg/DatePickIcon.vue'
import LoadingIndicator from '@/components/LoadingIndicator.vue'
import CloseIcon from '@/components/svg/CloseIcon.vue'
import ArrowDownIcon from '@/components/svg/ArrowDown.vue'
import PaginationComponent from '@/components/Pagination.vue'
import PAGINATE_NEWS from '@/graphql/news/News.graphql'
import SliderComponent from '@/components/SliderComponent.vue'

export default {
  name: 'DepartmentNewsPage',
  async asyncData ({ store, apollo }) {
    await store.dispatch('GET_NEWS_PAGE_DATA', apollo)
  },
  data () {
    return {
      page: 1,
      first: 15,
      loading: false,
      isFilterVisible: false,
      flatPickrConfig: {
        locale: Russian,
        enableTime: true,
        dateFormat: 'd.m.Y H:i',
        disableMobile: true
      },
      filterData: {},
      form: {
        title: {
          value: null,
          message: null
        },
        category_id: {
          value: null,
          message: null
        },
        date_start: {
          value: null,
          message: null
        },
        date_end: {
          value: null,
          message: null
        }
      }
    }
  },
  created () {
    this.load()
  },
  computed: {
    news () {
      return this.$store.state.news
    },
    firstNews () {
      return this.news && this.news.data && this.news.data.length ? this.news.data.slice(0, 3) : []
    },
    newsList () {
      return this.news && this.news.data && this.news.data.length > 3 ? this.news.data.slice(3) : []
    },
    categories () {
      let newCategories = []
      this.$store.state.news_categories.forEach((item) => {
        newCategories.push(item)
        if (item.child_categories && item.child_categories.length) {
          newCategories = newCategories.concat(
            item.child_categories
          )
        }
      })
      return newCategories
    },
    isFilter () {
      const keys = Object.keys(this.filterData)
      return keys && keys.length
    }
  },
  methods: {
    changeFilterForm () {
      const filter = this.$refs.filterForm
      filter.style.display = this.isFilterVisible ? 'none' : 'flex'
      this.isFilterVisible = !this.isFilterVisible
    },
    paginate (e) {
      this.page = e
      this.load()
      window.scrollTo(0, 0)
    },
    async load (page) {
      if (!this.loading) {
        this.loading = true
        this.filterData = {}
        Object.keys(this.form).forEach((key) => {
          if (key === 'category_id') {
            if (this.form[key].value) this.filterData[key] = this.form[key].value.id
          } else if (this.form[key].value !== null) this.filterData[key] = this.form[key].value
        })
        if (page) this.page = page
        await this.$apollo.provider.clients.dept
          .query({
            query: PAGINATE_NEWS,
            variables: Object.assign({
              ...this.filterData,
              type_id: this.$store.state.type.CODE_CITY_NEWS,
              page: this.page,
              first: this.first
            })
          })
          .then(({ data }) => {
            this.loading = false
            if (data.News) {
              this.$store.commit('news', data.News)
            }
            if (data.this_department) {
              this.$store.commit('this_department', data.this_department)
            }
          })
          .catch((err) => {
            this.loading = false
            console.error(err)
          })
      }
    },
    /**
     * Сброс фильтра
     */
    resetFilterForm () {
      Object.keys(this.form).forEach((key) => {
        this.form[key].value = null
      })
      this.paginate(1)
    },
    /**
     * Очищение строки поиска
     */
    resetSearchInput () {
      this.form.title.value = null
      // this.paginate(1)
    }
  },
  metaInfo () {
    return this.$seo(
      'common',
      'Официальный сайт Главы города Махачкалы',
      '',
      '',
      'Официальный сайт Главы города Махачкалы',
      '',
      ''
    )
  },
  jsonld () {
    return {
      '@context': 'https://schema.org',
      '@type': 'Organization'
    }
  },
  components: {
    FlatPickr,
    Multiselect,
    DatePickIcon,
    LoadingIndicator,
    CloseIcon,
    ArrowDownIcon,
    PaginationComponent,
    SliderComponent
  }
}
</script>

<style lang="stylus">
@import '~flatpickr/dist/flatpickr.min.css'
@import '~vue-multiselect/dist/vue-multiselect.min.css'

.news {
  &__filter {
    margin-bottom: 24px;
  }

  &__list {
    display flex
    flex-direction column
    gap 32px
    margin-top 24px
  }
}
</style>
